<template>
   <div class="policies-table">
        <iq-card>
           <template v-slot:headerTitle>
            <h4 class="card-title">Policies</h4>
          </template>
       
          <template v-slot:body>
         <tab-nav :tabs="true" id="myTab-two" class="mb-0">
              <tab-nav-items :active="true" id="hr-tab" ariaControls="HR Policies" href="#hr" role="tab" :ariaSelected="true" title="HR Policies" />
              <tab-nav-items :active="false" id="finance-tab" ariaControls="Finance Policies" href="#finance"  role="tab" :ariaSelected="false" title="Finance Policies" />
              <tab-nav-items :active="false" id="guide-tab" ariaControls="Tech guide"  role="tab" href="#guide" :ariaSelected="false" title="Tech Guide" />
              <tab-nav-items :active="false" id="team-tab" ariaControls="Team Structure"  role="tab" href="#team" :ariaSelected="false" title="Team Structure" />
           </tab-nav>
          </template>
        </iq-card>
        
            <tab-content id="myTabContent-1">
              <tab-content-item :active="true" id="hr" aria-labelled-by="hr-tab">
              <iq-card>            
               <template v-slot:body class="P-0">
                      <table class="table table-striped">
                        <thead>
                          <tr>                           
                          <th> Hr policies</th>
                          <th>File type</th>
                          <th>Date</th>
                          <th>Size</th>
                          <th>Author</th>
                          <th>Action</th>
                          </tr>
                        </thead>
                        <tbody v-if='hrpolicies'>
                          <tr v-for="hrPolicy in hrpolicies" :key="hrPolicy.id">
                            <td><img src="../../../src/assets/images/icon/pdf.png" width="32px" height="32px" alt="pdf" class=" mr-2">{{hrPolicy.name}}</td>
                             <td>{{hrPolicy.fileType}}</td>
                             <td>{{hrPolicy.date}}</td>
                             <td>{{hrPolicy.fileSize}}</td>
                             <td>{{hrPolicy.author}}</td>
                            <td>
                             <a :href="hrPolicy.path" download="" variant=" mr-1 btn " size="sm" target="_blank" >
                              <p class="iq-bg-danger p-1 d-inline"> <i class="ri-download-2-line mr-0  "></i></p></a>
                            </td>
                          </tr>
                        </tbody>
                        </table>
               <b-row>
             
            </b-row>
                </template>
                </iq-card>
              </tab-content-item>

              <tab-content-item  id="finance" aria-labelled-by="finance-tab">
                   <iq-card>            
               <template v-slot:body class="P-0">
                      <table class="table table-striped">
                        <thead>
                          <tr>                           
                           <th> Finance policies</th>
                          <th>File type</th>
                          <th>Date</th>
                          <th>Size</th>
                          <th>Author</th>
                          <th>Action</th>
                          </tr>
                        </thead>
                        <tbody v-if='financialpolicies'>
                           <tr v-for="fPolicy in financialpolicies" :key="fPolicy.id">
                            <td><img src="../../../src/assets/images/icon/pdf.png" width="32px" height="32px" alt="pdf" class=" mr-2">{{fPolicy.name}}</td>
                             <td>{{fPolicy.fileType}}</td>
                             <td>{{fPolicy.date}}</td>
                             <td>{{fPolicy.fileSize}}</td>
                             <td>{{fPolicy.author}}</td>
                            <td>
                              <a :href="fPolicy.path" download="" variant=" mr-1 btn " size="sm" target="_blank" >
                              <p class="iq-bg-danger p-1 d-inline"> <i class="ri-download-2-line mr-0  "></i></p></a>
                            </td>
                          </tr>
                        </tbody>
                        </table>
               <b-row>
             
            </b-row>
                </template>
                </iq-card>               
              </tab-content-item>

              <tab-content-item :active="false" id="guide" aria-labelled-by="profile-tab-two">
                   <iq-card>            
               <template v-slot:body class="P-0">
                      <table class="table table-striped">
                        <thead>
                          <tr>                           
                           <th> Tech Guide</th>
                          <th>File type</th>
                          <th>Date</th>
                          <th>Size</th>
                          <th>Author</th>
                          <th>Action</th>
                          </tr>
                        </thead>
                        <tbody v-if='techpolicies'>
                           <tr v-for="tPolicy in techpolicies" :key="tPolicy.id">
                            <td><img src="../../../src/assets/images/icon/pdf.png" width="32px" height="32px" alt="pdf" class=" mr-2">{{tPolicy.name}}</td>
                             <td>{{tPolicy.fileType}}</td>
                             <td>{{tPolicy.date}}</td>
                             <td>{{tPolicy.fileSize}}</td>
                             <td>{{tPolicy.author}}</td>
                            <td>
                              <a :href="tPolicy.path" download="" variant=" mr-1 btn " size="sm" target="_blank" >
                              <p class="iq-bg-danger p-1 d-inline"> <i class="ri-download-2-line mr-0  "></i></p></a>
                            </td>
                          </tr>
                        </tbody>
                        </table>
               <b-row>
             
            </b-row>
                </template>
                </iq-card>  
              </tab-content-item>

              <tab-content-item  id="team" aria-labelled-by="team-tab">
                   <iq-card>            
               <template v-slot:body class="P-0">
                      <table class="table table-striped">
                        <thead>
                          <tr>                           
                           <th> Team Structure</th>
                          <th>File type</th>
                          <th>Date</th>
                          <th>Size</th>
                          <th>Author</th>
                          <th>Action</th>
                          </tr>
                        </thead>
                        <tbody v-if='teampolicies'>
                           <tr v-for="tsPolicy in teampolicies" :key="tsPolicy.id">
                            <td><img src="../../../src/assets/images/icon/pdf.png" width="32px" height="32px" alt="pdf" class=" mr-2">{{tsPolicy.name}}</td>
                             <td>{{tsPolicy.fileType}}</td>
                             <td>{{tsPolicy.date}}</td>
                             <td>{{tsPolicy.fileSize}}</td>
                             <td>{{tsPolicy.author}}</td>
                            <td>
                              <a :href="tsPolicy.path" download="" variant=" mr-1 btn " size="sm" target="_blank" >
                              <p class="iq-bg-danger p-1 d-inline"> <i class="ri-download-2-line mr-0  "></i></p></a>
                            </td>
                          </tr>
                        </tbody>
                        </table>
               <b-row>
             
            </b-row>
                </template>
                </iq-card>               
              </tab-content-item>
            </tab-content>
   </div>
</template>
<script>
import { socialvue } from '../../config/pluginInit'
import axios from '../../components/axios'
export default {
  name: 'Policies',
  mounted () {
    socialvue.index()
    var parent = this
    axios.defaults.headers["Authorization"] = 
    "Bearer " + localStorage.getItem("token");

    const config = {
      method: "get",
      url: "policies",
    };

    axios(config).then(res => {
        parent.hrpolicies = res.data.hrpolicies
        parent.financialpolicies = res.data.financialpolicies
        parent.techpolicies = res.data.techpolicies
        parent.teampolicies = res.data.teampolicies
        // parent.suggestions = res.data.birthday
    }).catch(err => {
          console.log(err.response);
    })
  },  
  methods: {
    add () {
      let obj = this.default()
      this.rows.push(obj)
    },
    default () {
      
      return {
       
        id: this.rows.length,
        hrpolicies: '',
        filetype: 'pdf',
        date: '',
        size: '',
        author: '',
        editable: false,
        
      }
    },
    edit (item) {
      item.editable = true
    },
    submit (item) {
      item.editable = false
    },
    remove (item) {
      let index = this.rows.indexOf(item)
      this.rows.splice(index, 1)
    }
  },
  data () {
    return {
      hrpolicies:[],
      financialpolicies:[],
      techpolicies:[],
      teampolicies:[]
    }
  }
};

</script>

